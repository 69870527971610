/* eslint-disable no-unused-vars */
import dayjs from 'dayjs';
import _ from 'lodash';
import Store from '@/store/index';
import OrderWorkDateTypeEnumList from '../components/enums/orderWorkDateTypeEnumList';
export function getDeepValue (key, data) {
  return key.split('.').reduce((p, c)=>{
    return p && p[c];
  }, data);
}
export function getDisplayValue (field, model) {
  let config = field.__config__ || field;
  let value = getDeepValue(config.renderKey, model);
  let getOptionValue = (isMultiple)=>{
    if (field.__slot__?.options) {
      return isMultiple
        ? field.__slot__.options.filter(t => value && value.indexOf(t.value) >= 0)
        : field.__slot__.options.find(t => t.value === value);
    } else {
      return null;
    }
  };
  let timestampFormat = (val, format = 'yyyy-MM-dd')=>{
    if (val === null || val === undefined) {
      return '';
    }
    return dayjs(+val).format(format.replace(/y/g, 'Y').replace(/d/g, 'D'));
  };
  if (config.tag === 'el-select') {
    if (field.multiple) {
      return value ? value.map(t=>t.label).join(',') : '';
    } else {
      return value?.label;
    }
  } else if (config.tag === 'span' || config.tag === 'el-input') {
    return (value === undefined || value === null) ? '' : value;
  } else if (config.tag === 'el-input-number') {
    return _.isNil(value) ? '' : value;
  } else if (config.tag === 'el-radio-group') {
    value = getOptionValue();
    return value?.label;
  } else if (config.tag === 'el-checkbox-group') {
    value = getOptionValue(true);
    return value ? value.map(t=>t.label).join(',') : '';
  } else if (config.tag === 'el-time-picker') {
    if (!value) {
      return '';
    }
    if (field['is-range']) {
      if (field['value-format'] === 'timestamp') {
        return value.map(t=>timestampFormat(t, field.format)).join(field['range-separator'] || '~');
      } else {
        return value.join(field['range-separator'] || '~');
      }
    } else {
      if (field['value-format'] === 'timestamp') {
        return timestampFormat(value, field.format);
      } else {
        return value;
      }
    }
  } else if (config.tag === 'el-date-picker') {
    if (!value) {
      return '';
    }
    if (field['type'] === 'daterange' || field['type'] === 'datetimerange') {
      if (field['value-format'] === 'timestamp') {
        return value.map(t=>timestampFormat(t, field.format)).join(field['range-separator'] || '~');
      } else {
        return value.join(field['range-separator'] || '~');
      }
    } else {
      if (field['value-format'] === 'timestamp') {
        // element的format是yyyy-MM-dd 需要转换成dayjs YYYY-MM-DD
        return timestampFormat(value, field.format);
      } else {
        return value;
      }
    }
  } else if (config.tag === 'c-province-address') {
    if (field.addresstype === 4) {
      return `${value?.provinceName || ''}${value?.cityName || ''}${value?.areaName || ''}`;
    } else {
      return `${value?.provinceName || ''}${value?.cityName || ''}${value?.areaName || ''}${value?.detailAddress || ''}`;
    }
  } else if (config.tag === 'c-location') {
    return `${value?.address || ''}`;
  } else if (config.tag === 'c-department-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-user-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-customer-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-contact-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-service-provider') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-common-enterprise') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-device-selector' || config.tag === 'c-device-common-selector' || config.tag === 'c-template-selector') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-archive-collection') {
    return `${value?.name || ''}`;
  } else if (config.tag === 'c-contact-address-selector') {
    return `${value?.address || ''}`;
  } else if (config.tag === 'c-contact-time') {
    const contactableType = OrderWorkDateTypeEnumList.find(t=>t.value === value?.contactableType);
    return `${contactableType?.name || ''} ${value?.contactableStart ? value?.contactableStart + '~' : ''} ${value?.contactableEnd || ''} `;
  } else {
    throw new Error(`${config.tag}未设置默认展示值`);
  }
}

export function getDefaultValueByTag (field) {
  let config = field.__config__ || field;

  if (config.defaultValue !== null && config.defaultValue !== undefined) {
    const currentDate = dayjs().valueOf();
    const MonthOfFirstDay = dayjs().startOf('month').valueOf();
    const MonthOfLastDay = dayjs().endOf('month').valueOf();
    const { employeeMsg: currentUser, departmentMsg: currentDepartment } = Store.state.User.currentUserInfo;
    let varaibleMap = {
      currentDate, MonthOfFirstDay, MonthOfLastDay, currentUser, currentDepartment,
    };
    Object.keys(varaibleMap).forEach(t=>{
      const key = '${' + t + '}';
      varaibleMap[key] = varaibleMap[t];
    });

    return _.isNil(varaibleMap[config.defaultValue]) ? config.defaultValue : varaibleMap[config.defaultValue];
  }
  if (config && config.tag === 'el-input') {
    return undefined;
  } else if (config.tag === 'span') {
    return undefined;
  } else if (config.tag === 'el-input-number') {
    return undefined;
  } else if (config.tag === 'c-manual-sign') {
    return undefined;
  } else if (config.tag === 'el-select') {
    if (field.multiple) {
      return [];
    } else {
      return {};
    }
  } else if (config.tag === 'c-archive-collection') {
    return {};
  } else if (config.tag === 'el-radio-group') {
    return undefined;
  } else if (config.tag === 'el-checkbox-group') {
    return [];
  } else if (config.tag === 'el-switch') {
    return false;
  } else if (config.tag === 'el-slider') {
    return undefined;
  } else if (config.tag === 'el-time-picker') {
    return undefined;
  } else if (config.tag === 'el-date-picker') {
    return undefined;
  } else if (config.tag === 'el-rate') {
    return undefined;
  } else if (config.tag === 'upload') {
    return [];
  } else if (config.tag === 'c-province-address') {
    return {};
  } else if (config.tag === 'c-location') {
    return {};
  } else if (config.tag === 'c-department-selector') {
    return {};
  } else if (config.tag === 'c-user-selector') {
    return {};
  } else if (config.tag === 'c-contact-time') {
    return {};
  } else if (config.tag === 'c-customer-selector') {
    return {};
  } else if (config.tag === 'c-contact-selector') {
    return {};
  } else if (config.tag === 'c-service-provider') {
    return {};
  } else if (config.tag === 'c-contact-address-selector') {
    return {};
  } else if (config.tag === 'c-common-enterprise') {
    return {};
  } else if (config.tag === 'c-device-selector' || config.tag === 'c-device-common-selector' || config.tag === 'c-template-selector') {
    return {};
  } else if (config.tag === 'c-work-hour') {
    return [];
  } else if (config.tag === 'c-spare-part-list') {
    return [];
  } else if (config.tag === 'c-maintenance-list') {
    return [];
  } else if (config.layout === 'tableColumnFormItem' || config.layout === 'childFormItem') {
    return [];
  } else {
    throw new Error(`${config.tag}未设置默认值类型`);
  }
}

export function getDataTypeByTag (field) {
  let config = field.__config__ || field;
  if (config && config.tag === 'el-input') {
    return String;
  } else if (config.tag === 'span') {
    return String;
  } else if (config.tag === 'el-input-number') {
    return Number;
  } else if (config.tag === 'c-manual-sign') {
    return String;
  } else if (config.tag === 'el-select') {
    if (field.multiple) {
      return Array;
    } else {
      return Object;
    }
  } else if (config.tag === 'c-archive-collection') {
    return Object;
  } else if (config.tag === 'el-radio-group') {
    return String;
  } else if (config.tag === 'el-checkbox-group') {
    return Array;
  } else if (config.tag === 'el-switch') {
    return Boolean;
  } else if (config.tag === 'el-slider') {
    return Number;
  } else if (config.tag === 'el-time-picker') {
    if (field['is-range']) {
      return Array;
    } else {
      if (field['value-format'] === 'timestamp') {
        return Number;
      } else {
        return String;
      }
    }
  } else if (config.tag === 'el-date-picker') {
    if (field['type'] === 'daterange' || field['type'] === 'datetimerange') {
      return Array;
    } else {
      if (field['value-format'] === 'timestamp') {
        return Number;
      } else {
        return String;
      }
    }
  } else if (config.tag === 'el-rate') {
    return Number;
  } else if (config.tag === 'upload') {
    return Array;
  } else if (config.tag === 'c-province-address') {
    return Object;
  } else if (config.tag === 'c-location') {
    return Object;
  } else if (config.tag === 'c-department-selector') {
    return Object;
  } else if (config.tag === 'c-user-selector') {
    return Object;
  } else if (config.tag === 'c-contact-time') {
    return Object;
  } else if (config.tag === 'c-customer-selector') {
    return Object;
  } else if (config.tag === 'c-contact-selector') {
    return Object;
  } else if (config.tag === 'c-service-provider') {
    return Object;
  } else if (config.tag === 'c-common-enterprise') {
    return Object;
  } else if (config.tag === 'c-contact-address-selector') {
    return Object;
  } else if (config.tag === 'c-device-selector' || config.tag === 'c-device-common-selector' || config.tag === 'c-template-selector') {
    return Object;
  } else if (config.tag === 'c-work-hour') {
    return Array;
  } else if (config.tag === 'c-spare-part-list') {
    return Array;
  } else if (config.tag === 'c-maintenance-list') {
    return Array;
  } else if (config.layout === 'tableColumnFormItem' || config.layout === 'childFormItem') {
    return Array;
  } else {
    return undefined;
  }
}

const ruleTrigger = {
  'el-input': 'blur',
  'el-input-number': 'blur',
  'el-select': 'change',
  'el-radio-group': 'change',
  'el-checkbox-group': 'change',
  'el-cascader': 'change',
  'el-time-picker': 'change',
  'el-date-picker': 'change',
  'el-rate': 'change',
};

// 构建表单校验规则
export function buildRules (componentList, rules) {
  componentList?.length && componentList.forEach(cur => {
    const config = cur.__config__;
    let ruleList = config.regList || [];
    if (config.children && config.layout === 'groupRowFormItem') {
      buildRules(config.children, rules);
    } else {
      let type = getDataTypeByTag(cur);
      if (config.required) {
        const required = { message: `${config.label}不能为空`, trigger: ['blur', 'change'] };
        if (type === Array) {
          required.type = 'array';
          required.required = true;
        } else if (type === Object) {
          required.type = 'object';
          required.required = true;
        } else if (type === Number) {
          required.validator = (rule, value, callback)=>{
            if (value === null || value === undefined) {
              callback(new Error(`${config.label}不能为空`));
            } else if (isNaN(value)) {
              callback(new Error(`${config.label}格式不正确`));
            }
            callback();
          };
          required.type = 'number';
          required.required = true;
        } else if (type === Boolean) {
          required.type = 'boolean';
          required.required = true;
        } else if (type === String) {
          // 服务端会将"123"转成123，去掉类型校验
          // required.type = 'string';
          required.required = true;
        }
        ruleList.push(required);
        if (type === Object) {
          ruleList.push({
            validator: (rule, value, callback) => {
              if (!value || Object.keys(value).length === 0) {
                callback(new Error(`${config.label}不能为空`));
              }
              callback();
            },
            trigger: ['blur', 'change'],
          });
        }
        if (config.tag === 'c-location') {
          ruleList.push({
            validator: (rule, value, callback) => {
              if (!value || !value?.address) {
                callback(new Error(`${config.label}不能为空,请使用手机端进行签到`));
              }
              callback();
            },
            trigger: ['blur', 'change'],
          });
        }
        if (config.tag === 'c-province-address' && cur.addresstype === 4) {
          ruleList.push({
            validator: (rule, value, callback) => {
              if (!value || !value?.provinceId || !value?.detailAddress) {
                callback(new Error(`${config.label}不能为空`));
              }
              callback();
            },
            trigger: ['blur', 'change'],
          });
        }
      }
      rules[config.renderKey] = ruleList.map(item => {
        item.pattern && (item.pattern = new RegExp(item.pattern, 'g'));
        item.trigger = ruleTrigger && ruleTrigger[config.tag];
        return item;
      });
    }
  });
}

// 拍平自定义表单所有控件对应的字段
export function flatternFields (fields, fieldMap) {
  fieldMap = fieldMap || {};
  fields && fields.forEach(f=>{
    if (f?.__config__?.layout === 'groupRowFormItem') {
      flatternFields(f?.__config__?.children, fieldMap);
    } else {
      fieldMap[f?.__config__?.renderKey] = f;
    }
  });
  return fieldMap;
}

// 系统组件，工时、备件模拟自定义组件字段列表
export function getSystemComponentFixedFields (node) {
  let componentFields = [];
  if (node?.__config__?.tag === 'c-spare-part-list') {
    if (node.businessType === 2) {
      componentFields = [
        {
          __config__: {
            label: '来源',
            renderKey: 'source',
            tag: 'el-radio-group',
          },
          __slot__: {
            options: [
              {
                label: '公司备件',
                value: '2',
              },
              {
                label: '厂家备件',
                value: '3',
              },
            ],
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'sparePartName',
            label: '备件名称',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'sparePartCode',
            label: '备件编码',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'spec',
            label: '规格型号',
          },
        },
        {
          __config__: {
            tag: 'el-time-picker',
            renderKey: 'serialNumbers',
            label: '备件序列号',
          },
          'is-range': true,
          'range-separator': ',',
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'quantity',
            label: '数量',
          },
        },
      ];
    } else {
      componentFields = [
        {
          __config__: {
            label: '来源',
            renderKey: 'source',
            tag: 'el-radio-group',
          },
          __slot__: {
            options: [
              {
                label: '手工新增',
                value: 'MANUALLY_ADDED',
              },
              {
                label: '备件库',
                value: 'BACKUP_STORAGE',
              },
            ],
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'name',
            label: '备件名称',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'code',
            label: '备件编码',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'spec',
            label: '规格型号',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'serialNumber',
            label: '备件序列号',
          },
        },
        {
          __config__: {
            tag: 'span',
            renderKey: 'sparePartNumber',
            label: '数量',
          },
        },
      ];
    }
  } else if (node?.__config__?.tag === 'c-work-hour') {
    componentFields = [
      {
        __config__: {
          tag: 'span',
          renderKey: 'principalName',
          label: '维修工程师',
        },
      },
      {
        __config__: {
          tag: 'el-date-picker',
          renderKey: 'maintenanceDate',
          label: '处理日期',
        },
        'value-format': 'timestamp',
        format: 'yyyy-MM-dd',
      },
      {
        __config__: {
          tag: 'el-time-picker',
          renderKey: 'startTime',
          label: '开始时间',
        },
        'value-format': 'timestamp',
        format: 'HH:mm:ss',
      },
      {
        __config__: {
          tag: 'el-time-picker',
          renderKey: 'endTime',
          label: '结束时间',
        },
        'value-format': 'timestamp',
        format: 'HH:mm:ss',
      },
      {
        __config__: {
          tag: 'span',
          renderKey: 'maintenanceWork',
          label: '维修时间',
        },
      },
      {
        __config__: {
          tag: 'span',
          renderKey: 'travelTime',
          label: '旅行时间',
        },
      },
      {
        __config__: {
          tag: 'el-radio-group',
          renderKey: 'endState',
          label: '设备状态',
        },
        __slot__: {
          options: [
            {
              label: '正常使用',
              value: 'NORMAL_USE',
            },
            {
              label: '完全停机',
              value: 'DEAD_HALT',
            },
            {
              label: '局限使用',
              value: 'LIMITED_USE',
            },
          ],
        },
      },
    ];
  }

  return componentFields;
}
