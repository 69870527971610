import { getDisplayValue, getDefaultValueByTag } from '../utils/component.js';
import {
  getCustomQueryColumn,
  getCommonQueryPageList,
  getCommonQueryPageDetail,
} from '@/api/commonForm';
import { queryLayoutByNodeApi } from '@/api/formSetting';
import server from '@/request';
import previewImage from '@/components/imagePreviewer/previewImage';
import { downloadAndZip } from '@/utils/download';
import CommonFormList from '@/components/commonForm';
export default {
  name: 'child_form_render',
  components: {
    Parser: () => import('../parser/Parser'),
  },
  data () {
    return {
      dialogVisible: false,
      tableSelectorVisible: false,
      tableSelectedItems: [],
      tableSelectedChanged: false,
      dataStatus: 'NEW', // NEW:新增， EDIT:编辑
      childFormModel: {},
      dataList: [],
      columnList: [],
      childForm: null,
      childFormBtns: [],
      pagination: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
      },
    };
  },
  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },
  computed: {
    _elFormItemSize () {
      return (this.elFormItem || {}).elFormItemSize;
    },
    inputSize () {
      return this.schema.__config__.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
    },
    inputDisabled () {
      return this.schema.disabled || (this.elForm || {}).disabled;
    },
  },
  props: {
    schema: Object,
    formModel: {
      type: Object,
      default: ()=>{
        return {};
      },
    },
    mode: {
      // 预览模式
      // 渲染模式
      type: String,
      default: 'render',
      validator: function (val) {
        if (!val) return true;
        return ['preview', 'render'].indexOf(val) >= 0;
      },
    },
  },
  watch: {
    'schema.relatedFormTemplateCode': function () {
      this.getTableColumns();
    },
  },
  methods: {
    getTableColumns () {
      if (!this.formModel || this.schema.relatedFormType === null || this.schema.relatedFormType === undefined) {
        return Promise.reject();
      }
      return getCustomQueryColumn({
        customizeSystemConfigCode: this.schema?.relatedFormType,
        customizeBusinessTypeCode: this.schema?.relatedFormTemplateCode,
      }).then(res=>{
        if (res.body) {
          res.body.forEach(col=>{
            this.columnList.push({
              tooltip: true,
              label: col.columnName,
              minWidth: '160px',
              prop: col.columnKey,
              fixed: col.columnKey === 'code' ? 'left' : '',
              render: (h, { row }) => {
                if (col.columnKey === 'code') {
                  return (
                    <span class="text-primary cursor-pointer" onClick={this.handleAction.bind(this, row, 'EDIT')}>
                      {row.code}
                    </span>
                  );
                } else if (col.columnType === 'upload') {
                  // 特殊处理文件预览
                  const fileList = (row[col.columnKey] || []).map(t=>({url: t.url, type: t.type}));
                  if (!fileList[0]) {
                    return '--';
                  }
                  return <div>
                    <el-link type="primary" onClick={()=>previewImage({fileList})}>预览</el-link>
                    <el-divider direction="vertical"></el-divider>
                    <el-link type="primary" onClick={()=>downloadAndZip(fileList, row.code).then(()=>this.$message.success('下载成功'))}>下载</el-link>
                  </div>;
                } else {
                  const columnKeyInfo = row.columnKeyInfo || {};
                  const columnSchema = {
                    ...columnKeyInfo[col.columnKey],
                    __config__: {
                      tag: col.columnType,
                      renderKey: col.columnKey,
                    },
                  };
                  return getDisplayValue(columnSchema, row);
                }
              },
            });
          });
          // 关联引用支持删除
          if (this.schema.releatedType === 1 && !this.inputDisabled) {
            this.columnList.push({
              tooltip: true,
              title: '操作',
              minWidth: '100px',
              fixed: 'right',
              slots: {
                default: ({row }) =>{
                  return [(
                    <span class="text-primary cursor-pointer" onClick={()=>{
                      const selectedItems = this.formModel[this.schema.__config__.renderKey] || [];
                      const index = selectedItems.indexOf(row.id);
                      if (index >= 0) {
                        selectedItems.splice(index, 1);
                        this.$emit('input', selectedItems || []);
                        this.getTableDataList(false);
                      }
                    }}>
                      删除
                    </span>
                  )];
                },
              },
            });
          }
        }
      });
    },
    getTableDataList (isInit = true) {
      if (this.mode === 'preview') {
        return;
      }
      if (isInit) {
        this.pagination.currentPage = 1;
      }
      // 当查询的字段有值时再进行表格数据查询
      if (this.schema.relatedFormType) {
        let params = {
          systemConfigCodeFlag: this.schema?.relatedFormType,
          type: this.schema?.relatedFormTemplateCode,
          pageSize: this.pagination.pageSize,
          pageNum: this.pagination.currentPage,
        };
        if (this.schema.releatedType === 1) {
          // 关联引用
          params['idList'] = this.formModel[this.schema.__config__.renderKey] || [];
        } else {
          // 关联查询
          // 判断是否为引用查询
          if (this.schema.queryType === 'custom') {
            params['referenceId'] = this.formModel['id'];
            params['referenceCondition'] = true;
          } else {
            // 关联查询
            if (this.schema.relatedFormFillField) {
            // 兼容旧的单筛选条件支持
              params[this.schema.relatedFormFillField] = this.formModel[this.schema.relatedFormQueryBy];
            } else {
            // 新版多条件查询
              if (this.schema.filters) {
                this.schema.filters.forEach(f=>{
                  if (f.valueType === '1') {
                    params[f.relatedFormFillField] = f.relatedFormQueryBy;
                  } else {
                    params[f.relatedFormFillField] = this.formModel[f.relatedFormQueryBy];
                  }
                });
              }
            }
          }
        }
        getCommonQueryPageList(params).then(res=>{
          this.dataList = res.body?.list || [];
          this.pagination.total = res.body?.total;
        });
      }
    },
    handleFormBtnClick (btn) {
      const getQueryString = url=>{
        let ret = {};
        if (url) {
          const search = url.split('?');
          if (search[1]) {
            const searchParams = new URLSearchParams(search[1]);
            searchParams.forEach((value, key)=>{
              ret[key] = value;
            });
          }
        }
        return ret;
      };
      this.$refs[this.schema.__config__.renderKey].validForm().then(()=>{
        let params = {
          ...this.childFormModel,
          ...getQueryString(btn.pcUrl),
        };
        params[this.schema.relatedFormFillField] = this.formModel[this.schema.relatedFormQueryBy];
        server[btn.httpMethod](btn.pcUrl, params).then(()=>{
          this.dialogVisible = false;
          this.getTableDataList();
        });
      });
    },
    handleAction (row, action) {
      if (action === 'EDIT') {
        getCommonQueryPageDetail({
          id: row.id,
          systemConfigCodeFlag: this.schema?.relatedFormType,
        }).then(res=>{
          queryLayoutByNodeApi({
            customizeBusinessTypeId: res.body?.typeMsg?.id,
            status: res.body?.statusMsg.value,
          }).then(model=>{
            let formConf = model.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
            this.childForm = formConf;
            if (this.inputDisabled) {
              this.childForm.disabled = true;
            } else {
              this.childFormBtns = model.body?.customizeBusinessProcessResDTO?.customizeButtonResDTOList?.filter(t=>t.pcUrl) || [];
              // 操作按钮为空时禁用表单
              if (!this.childFormBtns[0]) {
                this.childForm.disabled = true;
              }
            }
            this.getViewModel(this.childForm.fields);
            Object.keys(res.body).forEach(key=>{
              if (res.body[key] !== null && res.body[key] !== undefined) {
                this.$set(this.childFormModel, key, res.body[key]);
              }
            });
            this.dataStatus = 'EDIT';
            this.dialogVisible = true;
            this.$nextTick(()=>{
              this.$refs[this.schema.__config__.renderKey].resetForm();
            });
          });
        });
      }
    },
    getViewModel (nodeList) {
      nodeList && nodeList.forEach(node=>{
        if (node?.__config__?.layout === 'groupRowFormItem') {
          this.getViewModel(node?.__config__?.children);
        } else {
          this.$set(this.childFormModel, node.__config__.renderKey, getDefaultValueByTag(node));
        }
      });
    },
    handleNew () {
      queryLayoutByNodeApi({
        code: this.schema?.relatedFormTemplateCode,
        status: 'NEW',
      }).then(res=>{
        let formConf = res.body?.customizeBusinessProcessResDTO?.customizeLayoutResDTO?.schemaDesc || {};
        this.childForm = formConf;
        this.childFormBtns = res.body?.customizeBusinessProcessResDTO?.customizeButtonResDTOList.filter(t=>t.pcUrl) || [];
        this.getViewModel(this.childForm.fields);
        this.childFormModel.typeMsg = res.body.customizeBusinessTypeResDTO;
        this.dialogVisible = true;
        this.dataStatus = 'NEW';
        this.$nextTick(()=>{
          this.$refs[this.schema.__config__.renderKey].resetForm();
        });
      });
    },
    handleRefrenceValuesChanged () {
      this.tableSelectorVisible = false;
      if (!this.tableSelectedChanged) {
        return;
      }
      this.$emit('input', this.tableSelectedItems?.map(t=>t.id) || []);
      if (!this.schema.allowMultipleSelect && this.schema.fillFields && this.tableSelectedItems && this.tableSelectedItems[0]) {
        const sourceFormValue = this.tableSelectedItems[0];
        this.schema.fillFields.forEach(t=>{
          this.formModel[t.targetField] = sourceFormValue[t.sourceField];
        });
      }
      this.getTableDataList();
    },
    cancelDialog () {
      this.childFormModel = {};
      this.dialogVisible = false;
    },
  },
  render () {
    let columns = [].concat(this.columnList);
    let leftButtons = [];
    if (!this.inputDisabled) {
      if (this.schema.allowCreate) {
        leftButtons.push({
          name: '新增', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: this.inputSize, // 大小尺寸
          click: this.handleNew,
        });
      }
      if (this.schema.releatedType === 1) {
        leftButtons.push({
          name: '选择', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: this.inputSize, // 大小尺寸
          click: ()=>{
            this.tableSelectorVisible = true;
            this.tableSelectedChanged = false;
          },
        });
      }
    }
    let ignoreQueryFilterKeys = [];
    let defaultQueryForm = {};
    if (this.schema.filters) {
      this.schema.filters.forEach(t=>{
        ignoreQueryFilterKeys.push(t.relatedFormFillField);
        if (t.valueType === '1') {
          defaultQueryForm[t.relatedFormFillField] = t.relatedFormQueryBy;
        } else {
          defaultQueryForm[t.relatedFormFillField] = this.formModel[t.relatedFormQueryBy];
        }
      });
    }

    let defaultSelectedIds = this.formModel[this.schema.__config__.renderKey] || [];
    const table = (<table-with-pagination maxHeight="500px"
      tableData={this.dataList}
      ref="orderTable"
      height={this.mode === 'preview' ? 120 : null}
      size={this.inputSize}
      show-right-toolbar={false}
      pagination={this.pagination}
      columnList={columns}
      {...{on: {
        'current-change': ()=>{ this.getTableDataList(false); },
        'update:pagination': val => {
          this.pagination = val;
        },
      }}}
      leftToolbar={leftButtons}></table-with-pagination>);
    const dialog = <el-dialog title={this.inputDisabled ? '查看详情' : (this.dataStatus === 'NEW' ? '新增' : '编辑')}
      visible={true}
      custom-class="common_form_dialog_wrapper"
      close-on-press-escape={false}
      close-on-click-modal={false}
      append-to-body={true}
      width="1300px"
      onClose={()=>{ this.cancelDialog(); }}>
      <div>
        <Parser ref={this.schema.__config__.renderKey}
          form-conf={this.childForm}
          formModel={this.childFormModel}/>
      </div>
      <span slot="footer">
        <el-button disabled={false} type="primary" plain onClick={()=>{ this.cancelDialog(); }}>取 消</el-button>
        {
          this.dataStatus === 'NEW' && this.childFormBtns.map(btn=>{
            return <el-button type="primary" key={btn.id} v-auth={btn.pcAuthCode} onClick={this.handleFormBtnClick.bind(this, btn)}>{btn.name}</el-button>;
          })
        }
      </span>
    </el-dialog>;
    const tableSelector = <el-dialog title={`选择${this.schema.relatedFormTemplateName}`}
      visible={true}
      close-on-click-modal={false}
      append-to-body={true}
      width="1300px"
      onClose={()=>{ this.tableSelectorVisible = false; }}>
      <CommonFormList configCode={this.schema.relatedFormType}
        typeFilterVisible={false}
        defaultQueryType={this.schema.relatedFormTemplateCode}
        defaultSelectedIds={defaultSelectedIds}
        ignoreQueryFilterKeys={ignoreQueryFilterKeys}
        defaultQueryForm={defaultQueryForm}
        renderMode={this.schema.allowMultipleSelect ? 'MULTIPLESELECT' : 'SINGLESELECT'}
        onSelectionChanged={val=>{ this.tableSelectedChanged = true; this.tableSelectedItems = val; }}/>
      <span slot="footer">
        <el-button onClick={()=>{ this.tableSelectorVisible = false; }}>取 消</el-button>
        <el-button type="primary" onClick={this.handleRefrenceValuesChanged}>确 定</el-button>
      </span>
    </el-dialog>;
    return <div>
      {table}
      {this.dialogVisible ? dialog : null}
      {this.tableSelectorVisible ? tableSelector : null}
    </div>;
  },
  created () {
    this.getTableColumns().then(this.getTableDataList);
    if (this.mode === 'render' && this.schema?.releatedType !== 1) {
      if (this.schema.filters) {
        this.schema.filters.forEach(f=>{
          if (f.valueType !== '1') {
            this.$watch(`formModel.${f.relatedFormQueryBy}`, this.getTableDataList);
          }
        });
      }
    }
  },
};
