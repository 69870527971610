import dayjs from 'dayjs';
import OrderStatusEnum from '@/enums/OrderStatusEnum';
import OrderSourceEnum from '@/enums/OrderSourceEnum';
import { showModal } from '@/components/showModal';
import { invitatRegister, coordinationService, payServiceProvider } from '@/api/commonForm';
import { Message, MessageBox } from 'element-ui';
import Vue from 'vue';
// 服务商支付生成订单
const payServiceProviderOrder = ({ id }) => {
  payServiceProvider({ objId: id, orderType: '1' }).then(() => {
    Message.success({
      message: '已为服务商生成订单，请联系服务商登录小紫医疗进行付款。',
    });
  });
};
export const commonFormConfigJson = {
  // 工单-WORK_ORDER
  WORK_ORDER: {
    abbreviation: '工单',
    authList: {
      add: 'workOrder:add',
      edit: 'workOrder:edit',
      detail: 'workOrder:view',
    },
    hasCheckbox: false,
    actions: [
      {
        authKey: 'workOrder:delete',
        btnName: '删除',
        typeDesc: 'delete',
        conditionStatus: row => row.orderSource === OrderSourceEnum.系统内部 && (row.status === OrderStatusEnum.已取消 || row.status === OrderStatusEnum.待指派),
      },
    ],
  },
  // 设备档案类型-DEVICE_FILE
  DEVICE_FILE: {
    abbreviation: '资产台账',
    authList: {
      add: 'device:add',
      edit: 'device:edit',
      detail: 'device:view',
    },
    hasCheckbox: true,
    actions: [
      {
        authKey: 'device:delete',
        btnName: '删除',
        typeDesc: 'delete',
      },
    ],
  },
  // 产品档案档案-PRODUCT_FILE
  PRODUCT_FILE: {
    abbreviation: '产品档案',
    authList: {
      add: 'device:productArchives:list:add',
      edit: 'device:productArchives:list:update',
      detail: 'device:productArchives:list:detail',
    },
    actions: [
      {
        authKey: 'device:productArchives:list:delete',
        btnName: '删除',
        typeDesc: 'delete',
      },
    ],
  },
  // 基础档案-BASE_FILE
  BASE_FILE: {
    abbreviation: '基础档案',
    authList: {
      add: 'baseFile:add',
      edit: 'baseFile:edit',
      detail: 'baseFile:view',
    },
    actions: [
      {
        authKey: 'baseFile:delete',
        btnName: '删除',
        typeDesc: 'delete',
      },
    ],
  },
  // 知识库
  KNOWLEDGE_BASE: {
    abbreviation: '知识库',
    authList: {
      add: 'knowledgeBase:add',
      edit: 'knowledgeBase:edit',
      detail: 'knowledgeBase:view',
    },
    actions: [
      {
        authKey: 'knowledgeBase:delete',
        btnName: '删除',
        typeDesc: 'delete',
      },
    ],
  },
  // 盘点单
  INVENTORY: {
    abbreviation: '盘点单',
    authList: {
      add: 'inventory:add',
      edit: 'inventory:edit',
      detail: 'inventory:view',
    },
    actions: [
      {
        authKey: 'inventory:delete',
        btnName: '删除',
        typeDesc: 'delete',
        conditionStatus: row => ['STOCKTAK'].includes(row.status),
      },
    ],
  },
  // 报价单
  QUOTATION: {
    abbreviation: '报价单',
    authList: {
      add: 'quotation:add',
      edit: 'quotation:edit',
      detail: 'quotation:view',
    },
    actions: [
      {
        authKey: 'quotation:delete',
        btnName: '删除',
        typeDesc: 'delete',
      },
    ],
  },
  // 服务商列表
  SERVICE_PROVIDER: {
    abbreviation: '服务商',
    authList: {
      add: 'serviceProvider:add',
      edit: 'serviceProvider:edit',
      delete: 'serviceProvider:delete',
      detail: 'serviceProvider:view',
    },
    columns: {
      xmbStatusMsg: (h, row, callback) => {
        if (row.xmbStatusMsg.value === 'REGISTERED') {
          return '已注册';
        } else {
          return (
            <span
              class="text-primary cursor-pointer"
              onClick={() => {
                showModal({
                  title: '注册',
                  width: '400px',
                  ref: 'register_ref',
                  data: {
                    ruleForm: {
                      contacts: row.contacts,
                      contactsPhone: row.contactsPhone,
                    },
                    rules: {
                      contacts: [
                        { required: true, message: '请输入联系人', trigger: 'blur' },
                        { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' },
                      ],
                      contactsPhone: [
                        { required: true, message: '请输入手机号', trigger: 'blur' },
                        { pattern: /^1\d{10}$/, message: '请输入正确的手机号码', trigger: 'blur' },
                      ],
                    },
                  },
                  content (h, ctx) {
                    return (
                      <div class="register_box">
                        <div class="registerText">该服务商未开通小紫医疗，无法启用协同功能，您可在此处发起邀请。</div>
                        <el-form
                          {...{ attrs: { model: ctx.ruleForm, rules: ctx.rules } }}
                          ref="ruleForm"
                          label-width="120px"
                        >
                          <el-form-item label="联系人" prop="contacts">
                            <el-input vModel={ctx.ruleForm.contacts}></el-input>
                          </el-form-item>
                          <el-form-item label="手机号" prop="contactsPhone">
                            <el-input vModel={ctx.ruleForm.contactsPhone}></el-input>
                          </el-form-item>
                        </el-form>
                      </div>
                    );
                  },
                  confirmButtonText: '发起邀请',
                  footer (h, ctx) {
                    return (
                      <div>
                        <el-button
                          type="primary"
                          onClick={() => {
                            ctx.$refs['ruleForm']?.validate(async valid => {
                              if (valid) {
                                invitatRegister({
                                  id: row.id,
                                  contacts: ctx.ruleForm.contacts,
                                  contactsPhone: ctx.ruleForm.contactsPhone,
                                }).then(() => {
                                  Message.success('发起邀请成功');
                                  ctx.$refs['register_ref'].close();
                                  ctx.$refs.register_ref.$el.parentNode.removeChild(ctx.$refs.register_ref.$el);
                                  callback();
                                });
                              }
                            });
                          }}
                        >
                          发起邀请
                        </el-button>
                        <el-button
                          onClick={() => {
                            ctx.$refs['register_ref'].close();
                            ctx.$refs.register_ref.$el.parentNode.removeChild(ctx.$refs.register_ref.$el);
                          }}
                        >
                          取消
                        </el-button>
                      </div>
                    );
                  },
                });
              }}
            >
              {row.isInvitation === false ? '未注册' : '重新邀请'}
            </span>
          );
        }
      },
      coordinationStatusMsg: (h, row, callback) => {
        const coordinationDialogText = ({ status, date }) => {
          if (status === 'ENABLED') {
            return `协同功能生效中过期时间：${dayjs(date).format('YYYY-MM-DD HH:mm:ss')}`;
          } else {
            return '协同功能需要付费使用，启用后，请根据提示进行付款。';
          }
        };
        return (
          <span
            class="text-primary cursor-pointer"
            onClick={() => {
              showModal({
                title: '协同功能',
                width: '400px',
                ref: 'synergy_ref',
                data: {
                  isENABLED: row.coordinationStatus === 'ENABLED',
                },
                content (h, ctx) {
                  return (
                    <div class="synergy_box">
                      <div class="top_box">
                        <div class="top_label">启用协同功能</div>
                        <el-switch class="top_value" vModel={ctx.isENABLED} />
                      </div>
                      <div class="synergy_text">
                        {coordinationDialogText({
                          status: row.coordinationStatus,
                          date: row.indate,
                        })}
                      </div>
                    </div>
                  );
                },
                beforeClose: (action, instance, closeBack) => {
                  if (action === 'confirm') {
                    const param = {
                      id: row.id,
                      operation: instance.isENABLED ? 'ENABLED' : 'NOTENABLED',
                    };
                    coordinationService(param).then(() => {
                      Message.success('操作成功');
                      closeBack();
                      callback();
                    });
                  }
                },
              });
            }}
          >
            {row.coordinationStatusMsg?.label}
          </span>
        );
      },
      paymentStatusMsg: (h, row) => {
        const formatPayDialogText = ({ status, date }) => {
          let text = '';
          switch (status) {
          case 'NON_PAYMENT':
            text = '协同功能需要付费使用，您可在此处立即支付，或点击服务商支付为服务商生成支付订单。';
            break;
          case 'ACCOUNT_PAID':
            text = `过期时间：${dayjs(date).format(
              'YYYY-MM-DD HH:mm:ss',
            )} \n 协同功能生效中，您可在此处续费以延长过期时间。`;
            break;
          case 'EXPIRED':
            text = `过期时间：${dayjs(date).format('YYYY-MM-DD HH:mm:ss')} \n 当前已过期，请重新支付以启用协同功能。`;
            break;
          case 'AUDIT_FAILED':
            if (+new Date(date) < +new Date()) {
              text = '协同功能需要付费使用，您可在此处立即支付，或点击服务商支付为服务商生成支付订单。';
            } else {
              text = `过期时间：${dayjs(date).format(
                'YYYY-MM-DD HH:mm:ss',
              )} \n 协同功能生效中，您可在此处续费以延长过期时间。`;
            }
            break;
          default:
            break;
          }
          return text;
        };
        const formatPaymentText = ({ status }) => {
          if (status === 'ACCOUNT_PAID' || status === 'AUDIT_FAILED') {
            return '续费支付';
          } else {
            return '立即支付';
          }
        };
        // 支付
        return row.paymentStatus === 'UNDER_REVIEW' ? (
          row.paymentStatusMsg?.label
        ) : (
          <span
            class="text-primary cursor-pointer"
            onClick={() => {
              let payDialogText = formatPayDialogText({
                status: row.paymentStatus,
                date: row.indate,
              });
              let paymentText = formatPaymentText({
                status: row.paymentStatus,
              });
              MessageBox({
                title: '支付提示',
                message: h(
                  'div',
                  {
                    class: 'custom_box',
                  },
                  [
                    h(
                      'div',
                      {
                        class: 'custom_box_body',
                      },
                      payDialogText,
                    ),
                    h(
                      'div',
                      {
                        class: 'btn_box',
                      },
                      [
                        h(
                          'el-button',
                          {
                            class: 'pay_btn',
                            on: {
                              click: () => {
                                payServiceProviderOrder(row);
                                MessageBox.close();
                              },
                            },
                          },
                          '服务商支付',
                        ),
                        h(
                          'el-button',
                          {
                            class: 'pay_btn',
                            style: {
                              background: '#409eff',
                              color: '#fff',
                            },
                            on: {
                              click: () => {
                                Vue.prototype.$eventBus.$emit('openPaymentDialogManually', {
                                  defaultCheckedId: row.id,
                                  defaultCheckedName: row.name,
                                  payNameType: 'service',
                                });
                                MessageBox.close();
                              },
                            },
                          },
                          paymentText,
                        ),
                        h(
                          'el-button',
                          {
                            class: 'pay_btn',
                            on: {
                              click: () => {
                                MessageBox.close();
                              },
                            },
                          },
                          '取消',
                        ),
                      ],
                    ),
                  ],
                ),
                showCancelButton: false,
                showConfirmButton: false,
              });
            }}
          >
            {row.paymentStatusMsg?.label}
          </span>
        );
      },
    },
  },
  // 安装验收
  DEVICE_INSTALL_ACCEPTANCE: {
    abbreviation: '安装验收',
    authList: {
      add: 'deviceInstallAcceptance:add',
      edit: 'deviceInstallAcceptance:edit',
      detail: 'deviceInstallAcceptance:view',
    },
    actions: [
      {
        authKey: 'deviceInstallAcceptance:delete',
        btnName: '删除',
        typeDesc: 'delete',
        conditionStatus: row => ['TO_BE_INSTALL'].includes(row.status),
      },
      {
        authKey: 'deviceInstallAcceptance:install',
        btnName: '安装',
        conditionStatus: row => row.status === 'TO_BE_INSTALL',
      },
      {
        authKey: 'deviceInstallAcceptance:acceptance',
        btnName: '验收',
        conditionStatus: row => row.status === 'TO_BE_CHECK',
      },
    ],
  },
  // 设备报废
  DEVICE_SCRAP: {
    abbreviation: '设备报废',
    authList: {
      add: 'deviceScrap:add',
      edit: 'deviceScrap:edit',
      detail: 'deviceScrap:view',
    },
    actions: [
      {
        authKey: 'deviceScrap:delete',
        btnName: '删除',
        typeDesc: 'delete',
        conditionStatus: row => ['TO_BE_APPROVAL'].includes(row.status),
      },
      {
        authKey: 'deviceScrap:approval',
        btnName: '审核',
        conditionStatus: row => row.status === 'TO_BE_APPROVAL',
      },
    ],
  },
  // 设备转借
  DEVICE_LENDING: {
    abbreviation: '设备转借',
    authList: {
      add: 'deviceLending:add',
      edit: 'deviceLending:edit',
      detail: 'deviceLending:view',
    },
    actions: [
      {
        authKey: 'deviceLending:delete',
        btnName: '删除',
        typeDesc: 'delete',
        conditionStatus: row => ['UNDERAUDIT'].includes(row.status),
      },
      {
        authKey: 'deviceLending:borrowingAudit',
        btnName: '借用审核',
        conditionStatus: row => row.status === 'UNDERAUDIT',
      },
      {
        authKey: 'deviceLending:returning',
        btnName: '申请归还',
        conditionStatus: row => row.status === 'BORROWING',
      },
      {
        authKey: 'deviceLending:returningAudit',
        btnName: '归还审核',
        conditionStatus: row => row.status === 'RETURNING',
      },
    ],
  },
  // 年度计划
  ANNUAL_PLAN: {
    abbreviation: '年度计划',
    authList: {
      add: 'annualPlan:add',
      edit: 'annualPlan:update',
      detail: 'annualPlan:detail',
    },
    actions: [
      {
        authKey: 'annualPlan:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'annualPlan:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'annualPlan:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['REJECTED', 'APPROVED'].includes(row.status),
      },
      {
        authKey: 'annualPlan:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'annualPlan:agree',
        btnName: '同意', // 待设备科审核  & 待论证 & 待财务审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'TO_BE_DEMONSTRATED', 'EXAMINE_FINANCE'].includes(row.status),
      },
      {
        authKey: 'annualPlan:reject',
        btnName: '驳回', // 待设备科审核  & 待论证 & 待财务审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'TO_BE_DEMONSTRATED', 'EXAMINE_FINANCE'].includes(row.status),
      },
      {
        authKey: 'annualPlan:refuse',
        btnName: '拒绝', // 待设备科审核  & 待论证 & 待财务审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'TO_BE_DEMONSTRATED', 'EXAMINE_FINANCE'].includes(row.status),
      },
    ],
  },
  // 采购申请
  PURCHASE_REQUISITION: {
    abbreviation: '采购申请',
    authList: {
      add: 'purchaseRequisition:add',
      edit: 'purchaseRequisition:update',
      detail: 'purchaseRequisition:detail',
    },
    actions: [
      {
        authKey: 'purchaseRequisition:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'purchaseRequisition:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'purchaseRequisition:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['REJECTED', 'APPROVED'].includes(row.status),
      },
      {
        authKey: 'purchaseRequisition:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'purchaseRequisition:agree',
        btnName: '同意', // 待设备科审核  & 待委员会审核 & 待主管院长审核 & 待院长审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'EXAMINE_COMMITTEE', 'EXAMINE_DIRECTOR', 'EXAMINE_PRESIDENT'].includes(row.status),
      },
      {
        authKey: 'purchaseRequisition:reject',
        btnName: '驳回', // 待设备科审核  & 待委员会审核 & 待主管院长审核 & 待院长审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'EXAMINE_COMMITTEE', 'EXAMINE_DIRECTOR', 'EXAMINE_PRESIDENT'].includes(row.status),
      },
      {
        authKey: 'purchaseRequisition:refuse',
        btnName: '拒绝', // 待设备科审核  & 待委员会审核 & 待主管院长审核 & 待院长审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'EXAMINE_COMMITTEE', 'EXAMINE_DIRECTOR', 'EXAMINE_PRESIDENT'].includes(row.status),
      },
    ],
  },
  // 采购合同
  PURCHASE_CONTRACT: {
    abbreviation: '采购合同',
    authList: {
      add: 'purchaseContract:add',
      edit: 'purchaseContract:update',
      detail: 'purchaseContract:detail',
    },
    actions: [
      {
        authKey: 'purchaseContract:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'purchaseContract:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'purchaseContract:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['REJECTED', 'COMPLETED'].includes(row.status),
      },
      {
        authKey: 'purchaseContract:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'purchaseContract:confirm',
        btnName: '确认', // 待签订合同
        conditionStatus: row => ['TO_BE_SIGNED_CONTRACT'].includes(row.status),
      },
      {
        authKey: 'purchaseContract:reject',
        btnName: '驳回', // 待签订合同
        conditionStatus: row => ['TO_BE_SIGNED_CONTRACT'].includes(row.status),
      },
    ],
  },
  // 到货管理
  ARRIVAL_MANAGEMENT: {
    abbreviation: '到货管理',
    authList: {
      add: 'arrivalManagement:add',
      edit: 'arrivalManagement:update',
      detail: 'arrivalManagement:detail',
    },
    actions: [
      {
        authKey: 'arrivalManagement:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'arrivalManagement:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'arrivalManagement:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && row.status !== 'ARRIVED',
      },
      {
        authKey: 'arrivalManagement:submit',
        btnName: '到货', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
    ],
  },
  // 安装验收
  INSTALLATION_ACCEPTANCE: {
    abbreviation: '安装验收',
    authList: {
      add: 'installationAcceptance:add',
      edit: 'installationAcceptance:update',
      detail: 'installationAcceptance:detail',
    },
    actions: [
      {
        authKey: 'installationAcceptance:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'installationAcceptance:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'installationAcceptance:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['COMPLETED'].includes(row.status),
      },
      {
        authKey: 'installationAcceptance:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'installationAcceptance:install',
        btnName: '安装',
        conditionStatus: row => row.status === 'TO_BE_INSTALLED',
      },
      {
        authKey: 'installationAcceptance:cultivate',
        btnName: '培训',
        conditionStatus: row => row.status === 'TO_BE_TRAINED',
      },
      {
        authKey: 'installationAcceptance:accept',
        btnName: '验收',
        conditionStatus: row => row.status === 'TO_BE_ACCEPTED',
      },
    ],
  },
  // 入库管理
  WAREHOUSING_MANAGEMENT: {
    abbreviation: '入库管理',
    authList: {
      add: 'warehousingManagement:add',
      edit: 'warehousingManagement:update',
      detail: 'warehousingManagement:detail',
    },
    actions: [
      {
        authKey: 'warehousingManagement:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'warehousingManagement:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'warehousingManagement:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['IN_THE_LIBRARY', 'ISSUED'].includes(row.status),
      },
      {
        authKey: 'warehousingManagement:submit',
        btnName: '入库', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
    ],
  },
  // 出库管理
  OUTBOUND_MANAGEMENT: {
    abbreviation: '出库管理',
    authList: {
      add: 'outboundManagement:add',
      edit: 'outboundManagement:update',
      detail: 'outboundManagement:detail',
    },
    actions: [
      {
        authKey: 'outboundManagement:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'outboundManagement:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'outboundManagement:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['IN_USE'].includes(row.status),
      },
      {
        authKey: 'outboundManagement:submit',
        btnName: '出库', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
    ],
  },
  // 资产转科
  ASSET_TRANSFER: {
    abbreviation: '资产转科',
    authList: {
      add: 'assetTransfer:add',
      edit: 'assetTransfer:update',
      detail: 'assetTransfer:detail',
    },
    actions: [
      {
        authKey: 'assetTransfer:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'assetTransfer:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'assetTransfer:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['REJECTED', 'COMPLETED'].includes(row.status),
      },
      {
        authKey: 'assetTransfer:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'assetTransfer:agree',
        btnName: '同意', // 待设备科审核
        conditionStatus: row => ['EXAMINE_EQUIPMENT'].includes(row.status),
      },
      {
        authKey: 'assetTransfer:reject',
        btnName: '驳回', // 待设备科审核 & 待科室确认
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'TO_CONFIRMED'].includes(row.status),
      },
      {
        authKey: 'assetTransfer:refuse',
        btnName: '拒绝', // 待设备科审核 & 待科室确认
        conditionStatus: row => ['EXAMINE_EQUIPMENT', 'TO_CONFIRMED'].includes(row.status),
      },
      {
        authKey: 'assetTransfer:confirm',
        btnName: '确认', // 待科室确认
        conditionStatus: row => ['TO_CONFIRMED'].includes(row.status),
      },
    ],
  },
  // 保修合同
  WARRANTY_CONTRACT: {
    abbreviation: '保修合同',
    authList: {
      add: 'warrantyContract:add',
      edit: 'warrantyContract:update',
      detail: 'warrantyContract:detail',
    },
    actions: [
      {
        authKey: 'warrantyContract:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'warrantyContract:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'warrantyContract:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['NOT_STARTED', 'ADVENT', 'EXPIRED', 'NORMAL'].includes(row.status),
      },
      {
        authKey: 'warrantyContract:submit',
        btnName: '保存', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
    ],
  },
  // 不良事件
  ADVERSE_EVENT: {
    abbreviation: '不良事件',
    authList: {
      add: 'adverseEvent:add',
      edit: 'adverseEvent:update',
      detail: 'adverseEvent:detail',
    },
    actions: [
      {
        authKey: 'adverseEvent:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'adverseEvent:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'adverseEvent:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['FINISHED'].includes(row.status),
      },
      {
        authKey: 'adverseEvent:submit',
        btnName: '提交', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
      {
        authKey: 'adverseEvent:pass',
        btnName: '通过',
        conditionStatus: row => row.status === 'TO_BE_REVIEWED',
      },
      {
        authKey: 'adverseEvent:confirm',
        btnName: '确认',
        conditionStatus: row => row.status === 'TO_BE_ACCEPTANCE',
      },
      {
        authKey: 'adverseEvent:motion',
        btnName: '提案',
        conditionStatus: row => row.status === 'TO_BE_MOTION',
      },
      {
        authKey: 'adverseEvent:feedback',
        btnName: '反馈',
        conditionStatus: row => row.status === 'TO_BE_FEEDBACK',
      },
      {
        authKey: 'adverseEvent:appraise',
        btnName: '评价',
        conditionStatus: row => row.status === 'TO_BE_APPRAISE',
      },
      {
        authKey: 'adverseEvent:reject',
        btnName: '驳回', // 待审核 & 待受理 & 待提案 & 待反馈 & 待评价
        conditionStatus: row => ['TO_BE_REVIEWED', 'TO_BE_ACCEPTANCE', 'TO_BE_MOTION', 'TO_BE_FEEDBACK', 'TO_BE_APPRAISE'].includes(row.status),
      },
    ],
  },
  // 设备计量
  EQUIPMENT_METERING: {
    abbreviation: '设备计量',
    authList: {
      add: 'equipmentMetering:add',
      edit: 'equipmentMetering:update',
      detail: 'equipmentMetering:detail',
    },
    actions: [
      {
        authKey: 'equipmentMetering:delete',
        btnName: '删除', // 草稿
        typeDesc: 'delete',
        conditionStatus: row => row.status === 'DRAFT',
      },
      {
        authKey: 'equipmentMetering:add',
        btnName: '暂存', // 新建节点
        conditionStatus: row => !row.status,
      },
      {
        authKey: 'equipmentMetering:update',
        btnName: '暂存', // 非结束 & 非新建节点
        conditionStatus: row => !!row.status && !['COMPLETED'].includes(row.status),
      },
      {
        authKey: 'equipmentMetering:submit',
        btnName: '保存', // 新建和草稿
        conditionStatus: row => !row.status || row.status === 'DRAFT',
      },
    ],
  },
  CUSTOMIZE_PROCESS: {
    abbreviation: '自定义流程',
    authList: {
      add: 'customizeProcess:add',
      edit: 'customizeProcess:update',
      detail: 'customizeProcess:detail',
    },
  },
};
